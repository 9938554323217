<template>
  <vs-row vs-justify="center" class='merchant-otp-verification'>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="10" class="pt-4">
      <div class="flex justify-center">
        <img src="@/assets/images/logo/rellopay-logo.svg" alt="Rello" v-if="appName ==='RelloPay'" class="h-12 w-auto"/>
        <img src="@/assets/images/logo/flippay-logo.svg" alt="FlipPay" v-if="appName ==='FlipPay'" class="h-12 w-auto"/>
      </div>
      <vs-card class="mt-3 md:mt-8 bg-transparent otp-block">
        <div slot="header" class="px-6 md:px-8 py-4 md:py-6 text-center">
          <feather-icon icon="UserIcon" svgClasses="stroke-current mid-blue w-8 h-8" class="mb-3" />
          <h3 class="text-xl font-normal leading-normal">Access your account</h3>
        </div>
        <div class="px-6 lg:px-8 py-4 md:py-8">
          <p class="text-sm md:text-base font-light text-center px-0 lg:px-8"
            >Please enter the 6-digit code we just sent to {{email}} </p>
          <p v-if="isInvalidCodce" class="mt-4 md:mt-8 text-danger text-sm md:text-base font-normal text-center px-0">
            Verification code invaild. Please try again.

          </p>

          <div class="mt-6 md:mt-8 mb-2">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="onNumberChange"
              @paste.native="pasteNumber"
              class="flex justify-center"
              :class="isInvalidCodce?'invalidBox':''"
            />
          </div>
          <div class="text-sm text-center"
            >Didn’t get it?
            <a href="#" @click="sendCodeToSetupMerchant" class="text-link underline">Resend code</a></div>
          <div class="">
            <vs-button
              color="primary"
              v-round
              class="w-full mt-6 md:mt-8 large"
              @click="verifyOtp"
              :disabled="disabled"
            >
              Verify</vs-button
            >
          </div>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>
<script>
import CodeInput from "vue-verification-code-input";
import { mapActions } from "vuex";
export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      email:"",
      partnerId: this.$route.params.partnerId,
      hash : this.$route.query.hash,
      verificationCode: "",
      isInvalidCodce:false,
      appName: process.env.VUE_APP_NAME ||"FlipPay"
    };
  },
  methods: {
    ...mapActions("partner", ["sendOtpCodeToMerchant",'verifyOtpCodeOfMerchant']),

    onNumberChange(verificationCode) {
      this.verificationCode = verificationCode;
       this.isInvalidCodce = false;
    },
    verifyOtp() {
       this.isInvalidCodce = false;
      let payload = {
        partnerId:this.partnerId,
        hash:this.hash,
        verificationCode: this.verificationCode,
      };
      this.verifyOtpCodeOfMerchant(payload).then((res) => {

          this.$router.push({
            name: "setupCredentials",
            params: {partnerId: this.partnerId},
            query:{hash:this.hash}
          });

          this.$vs.notify({
            title: "OTP",
            text: "Verified successfully.",
            color: "success",
            position: "top-right",
            iconPack: "feather",
          });
        })
        .catch((err) => {
          this.isInvalidCodce = true;
        });
    },
    sendCodeToSetupMerchant() {
      let data = {
        partnerId:this.partnerId,
        hash:this.hash
      }
      this.sendOtpCodeToMerchant(data).then((res) => {
        this.$vs.notify({
          title: "OTP",
          text: "Verification code has been re-sent",
          color: "success",
          iconPack: "feather",
          position: "top-right",
        });
       // localStorage.setItem("email", this.email);

      });
    },
    pasteNumber(a){
      this.verificationCode = a.clipboardData.getData('text')
    }
  },
  computed: {
    disabled() {
      if (!this.verificationCode) return true;
      if (this.verificationCode.length != 6) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.email =  this.$route.query.email;
  },
};
</script>